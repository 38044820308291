import { useEffect, useRef, useState, type ReactElement } from 'react';

import { Box, Divider, MenuItem, Select, Typography } from '@mui/material';

import { ACCESS_TYPES, HOME_VIEW_TYPES } from 'common/interfaces/enums';

import type {
  SaveUploadedTypes,
  DisplayTableData,
  TransformedData
} from 'common/interfaces/interfaces';
import type { MergedDatasetValues } from 'mergeDatasets/MergeDatasets';

import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';

import PreviewRows from 'featureEngineering/previewRows/PreviewRows';
import MergeDuplicated from 'mergeDatasets/components/mergeDuplicatedAlert/MergeDuplicatedAlert';

import { Check } from '@mui/icons-material';
import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import {
  createErrorNotification,
  notifyMessageAtom
} from 'atoms/atomMessageError';
import { previewfiltersStateAtom } from 'bulkPredictions/atoms/atomPreviewfilersState';
import { updateFilterValue } from 'bulkPredictions/utils/summaryLayerUtils';
import { transformFeatureStateAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { MENU, SUB_MENU } from 'featureEngineering/featureEngineeringInterface';
import {
  getTransformationState,
  transformAction
} from 'featureEngineering/utils/transformationUtils';
import MergeColumnDetail from 'mergeDatasets/components/mergeColumnDetail/MergeColumnDetail';
import type { Filter } from 'models/Filter';
import { ExposeService } from 'services/ExposeService';
import { GAUserEvent } from 'utils/utils';

import {
  USER_TRACKING_MERGE_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import 'common/Common.scss';
import '../MergeDatasets.scss';
import './MergeDatasetsPreview.scss';
import RenderViewComposed from 'home/components/renderView/RenderView';
import CustomChips, { CHIPS_TYPE } from 'common/customChips/CustomChips';

export interface MergeDatasetsPreviewProps {
  displayTableData: DisplayTableData;
  keyIdentifiers: MergedDatasetValues;
}

enum FilterOptions {
  SHOW = 'Show all columns',
  MERGED = 'Merged columns',
  DUPLICATED = 'Duplicated columns',
  DISREGARDED = 'Disregarded columns'
}

const MergeDatasetsPreview = ({
  displayTableData,
  keyIdentifiers
}: MergeDatasetsPreviewProps): ReactElement => {
  const exposeService = ExposeService.getInstance();
  const [transformationState, setTransformState] = useRecoilState(
    transformFeatureStateAtom
  );
  const [filterOptions, setFilterOptions] = useRecoilState(
    previewfiltersStateAtom
  );

  const userLocationVariable = useRecoilValue(userTrackingLocation);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const setNotifyMessage = useSetRecoilState(notifyMessageAtom);

  const resetAtomTransform = useResetRecoilState(transformFeatureStateAtom);
  const resetAtomFilterOptions = useResetRecoilState(previewfiltersStateAtom);

  const [disableMergeAllButton, setDisableMergeAllButton] =
    useState<boolean>(false);

  const allExecptions = [
    ...keyIdentifiers.duplicatedColumns,
    ...keyIdentifiers.disregardedColumns
  ];

  const [disregardedColumns, setDisregardedColumns] = useState(
    Object.keys(transformationState)
  );

  const reach = displayTableData?.keys?.length ?? 0;

  const columnDetails = [
    {
      title: 'Total',
      label: 'Processed columns',
      value: FilterOptions.SHOW,
      tooltip: 'Total: the amount of columns to show',
      reach,
      progress: reach,
      color: '#07D188'
    },
    {
      title: 'Merged',
      label: 'Columns merged',
      value: FilterOptions.MERGED,
      tooltip: 'Merge: all the columns but the ones to review or discarded',
      reach,
      progress:
        reach -
        (keyIdentifiers.duplicatedColumns.length * 2 +
          keyIdentifiers.disregardedColumns.length),
      color: '#07D188'
    },
    {
      title: 'Duplicated',
      label: 'Partialy empty',
      value: FilterOptions.DUPLICATED,
      tooltip:
        'Duplicated: all those columns that are duplicated, counting each column.',
      reach,
      progress: keyIdentifiers.duplicatedColumns.length * 2,
      color: '#ffd597'
    },
    {
      title: 'Auto disregarded',
      label: 'Over 60% empty',
      value: FilterOptions.DISREGARDED,
      tooltip:
        'Auto discarded: all those that will be automatically discarded and cannot be used as they won’t be useful at all',
      reach,
      progress: keyIdentifiers.disregardedColumns.length,
      color: '#f04438'
    }
  ];

  const [filteredDisplayPreview, setFilteredDisplayPreview] = useState(
    columnDetails[0].value
  );

  const [sendData, setSendData] = useState<boolean>(false);
  const keyId = `${
    keyIdentifiers.selectedKeyId ?? displayTableData?.keys?.[0]
  }`;

  const initialDuplicatedColumns =
    keyIdentifiers?.duplicatedColumns.filter((duplicated) => {
      if (
        keyIdentifiers.selectedKeyId === duplicated ||
        keyIdentifiers.selectedKeyId === 'main_' + duplicated ||
        keyIdentifiers.disregardedColumns.includes(duplicated) ||
        keyIdentifiers.disregardedColumns.includes('main_' + duplicated)
      ) {
        return false;
      }
      return true;
    }) ?? [];

  const [duplicatedColumns, setDuplicatedColumns] = useState(
    initialDuplicatedColumns
  );
  const [openDuplicatedAlert, setOpenDuplicatedAlert] =
    useState<boolean>(false);

  const transformedStateDisregardedColumns = useRef({
    values: {},
    executed: false
  });

  if (
    !transformedStateDisregardedColumns.current.executed &&
    keyIdentifiers.disregardedColumns.length > 0
  ) {
    keyIdentifiers.disregardedColumns.forEach((disregardColumn) => {
      const foundSchema = keyIdentifiers?.schema.find(
        (schema) => schema.name === disregardColumn
      );
      if (foundSchema !== undefined) {
        const newTransformation = transformAction(
          transformationState,
          disregardColumn,
          MENU.COLUMN_SETTINGS,
          SUB_MENU.DISREGARD_COLUMN,
          {
            action: 'disregard_column',
            column: disregardColumn,
            type: foundSchema.dataValue
          }
        )(true);

        transformedStateDisregardedColumns.current.values = {
          ...transformedStateDisregardedColumns.current.values,
          ...newTransformation
        };
      }
    });
  }

  useEffect(() => {
    if (!transformedStateDisregardedColumns.current.executed) {
      transformedStateDisregardedColumns.current.executed = true;
      setTransformState({
        ...transformationState,
        ...transformedStateDisregardedColumns.current.values
      });
    }
  }, [keyIdentifiers]);

  useEffect(() => {
    if (
      filterOptions.keyFactor === undefined &&
      Boolean(keyId) &&
      filterOptions.factorsDisplay === undefined
    ) {
      const newFilter: { filter?: Filter | undefined } = {
        filter: updateFilterValue(filterOptions.filter)
      };
      setFilterOptions({
        ...filterOptions,
        ...(newFilter.filter !== undefined ? newFilter : {}),
        keyFactor: keyId
      });
    }
  }, [filterOptions]);

  useEffect(() => {
    const transformationKeys = Object.keys(transformationState);
    if (transformationKeys.length !== disregardedColumns.length) {
      setDisregardedColumns(transformationKeys);
      if (filteredDisplayPreview === 'Disregarded columns') {
        const factorsDisplay = displayTableData?.keys?.filter((key) =>
          transformationKeys.includes(key)
        );
        fixFilterFactorDisplays(factorsDisplay as string[]);
      }
    }
  }, [transformationState]);

  const handleSave = async (): Promise<void> => {
    if (duplicatedColumns.length > 0) {
      setOpenDuplicatedAlert(true);
    } else {
      continueMerge();
    }
  };

  const handleCancel = (): void => {
    discardMergeDuplicated();
    resetAtomTransform();
    setViewRender({
      type: HOME_VIEW_TYPES.LOAD_DATASETS,
      stored: viewRender.stored
    });
    exposeService
      .deleteDataset(keyIdentifiers.id)
      .then(() => {
        setSendData(false);
      })
      .catch(handleException);
  };

  const handleException = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_MERGE_ACTIONS.FAILURE}`
    );
    setSendData(false);
    createErrorNotification(
      'An error occurred while trying to merge the datasets',
      setNotifyMessage
    );
  };

  const handleDuplicatedColumn = (columnName: string): void => {
    if (
      duplicatedColumns.includes('main_' + columnName) ||
      duplicatedColumns.includes(columnName)
    ) {
      setDuplicatedColumns(
        duplicatedColumns.filter((duplicated) => {
          if (
            duplicated === columnName ||
            duplicated === 'main_' + columnName
          ) {
            return false;
          }
          return true;
        })
      );
    }
  };

  const sucessMergeCycle = (): void => {
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_MERGE_ACTIONS.SUCCESS}`
    );
    resetAtomTransform();
    resetAtomFilterOptions();
    setSendData(false);
    setViewRender({
      type: HOME_VIEW_TYPES.LOAD_DATASETS,
      stored: viewRender.stored
    });
  };

  const continueMerge = (): void => {
    setDisableMergeAllButton(true);
    setSendData(true);
    const newTransfomed = getTransformationState(transformationState);
    const disregardedColumns = newTransfomed.reduce((acumulat: string[], e) => {
      if (e.action === 'disregard_column') acumulat.push(e.column);
      return acumulat;
    }, []);

    const collectedSaveData: SaveUploadedTypes & TransformedData = {
      name: `${disregardedColumns.length}-${keyIdentifiers.name}`.slice(0, 25),
      description: `List of disregarded columns ${disregardedColumns.join(
        ', '
      )}`.slice(0, 255),
      visibility: ACCESS_TYPES.PRIVATE,
      tags: [],
      transformations: newTransfomed
    };

    exposeService
      .overwriteDataset(keyIdentifiers.id, collectedSaveData)
      .then(sucessMergeCycle)
      .catch(handleException);
  };

  const discardMergeDuplicated = (): void => {
    resetAtomFilterOptions();
    if (openDuplicatedAlert) {
      setOpenDuplicatedAlert(false);
    }
  };

  const fixFilterFactorDisplays = (factorsDisplay: string[]): void => {
    const { keyFactor, ...oldFilter } = filterOptions;
    const newFilter = { ...oldFilter, factorsDisplay };
    setFilterOptions(newFilter);
  };

  const headerComponent = (
    <Box className="merge-dataset-header">
      <Typography className="header-title">Merge multiple datasets</Typography>
      <CustomChips
        type={CHIPS_TYPE.ACCENT}
        className="alfa-feature"
        label={'Early access'}
      />
    </Box>
  );

  const bodyComponent = (
    <Box className="merge-dataset-body">
      <Typography className="ft-md">
        Take a look to the preview of merged dataset. Check any duplicated
        columns and decide if they should be included in the merged dataset. You
        can also remove any columns you are not interested in including in the
        final merged dataset.
      </Typography>
      <Box className="merge-dataset-preview">
        <Box className="merge-body-header">
          {columnDetails.map((displays, index) => {
            return (
              <MergeColumnDetail
                key={`${displays.title}-${index}-columnDetail`}
                title={displays.title}
                label={displays.label}
                tooltipText={displays.tooltip}
                reach={displays.reach}
                progress={displays.progress}
                color={displays.color}
              />
            );
          })}
          <Select
            className="column-details-select"
            value={filteredDisplayPreview}
            onChange={(value) => {
              setFilteredDisplayPreview(value.target.value as FilterOptions);
              switch (value.target.value) {
                case FilterOptions.SHOW: {
                  const { factorsDisplay, ...newFilter } = filterOptions;
                  setFilterOptions(newFilter);
                  break;
                }
                case FilterOptions.MERGED: {
                  const factorsDisplay = displayTableData?.keys?.filter(
                    (key) => {
                      if (key.includes('main_')) {
                        key = key.replace('main_', '');
                      }
                      return !allExecptions.includes(key);
                    }
                  );
                  fixFilterFactorDisplays(factorsDisplay as string[]);
                  break;
                }
                case FilterOptions.DUPLICATED: {
                  const factorsDisplay = displayTableData?.keys?.filter(
                    (key) => {
                      if (key.includes('main_')) {
                        key = key.replace('main_', '');
                      }
                      return keyIdentifiers.duplicatedColumns.includes(key);
                    }
                  );
                  fixFilterFactorDisplays(factorsDisplay as string[]);
                  break;
                }
                case FilterOptions.DISREGARDED: {
                  const factorsDisplay = displayTableData?.keys?.filter((key) =>
                    disregardedColumns.includes(key)
                  );
                  fixFilterFactorDisplays(factorsDisplay as string[]);
                  break;
                }
              }
            }}
          >
            {columnDetails.map((displays, index) => (
              <MenuItem
                className="justify-space-between"
                style={{ gap: '10px' }}
                key={`${displays.title}-${index}-menulist`}
                value={displays.value}
              >
                {displays.value}
                {filteredDisplayPreview === displays.value ? <Check /> : <></>}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {filterOptions.keyFactor === keyId ||
        filterOptions.factorsDisplay !== undefined ? (
          <PreviewRows
            displayTableData={displayTableData}
            mergeCompatibilityValues={keyIdentifiers}
            handleDuplicatedColumn={handleDuplicatedColumn}
            options={{
              pagination: true,
              style: { maxHeight: '350px' }
            }}
          />
        ) : null}
      </Box>
    </Box>
  );

  const footerComponent = (
    <Box className="justify-space-between">
      <CustomButton
        variant="secondary"
        onClick={handleCancel}
        data-cy="preview-merge-modal-cancel-button"
      >
        Cancel merge
      </CustomButton>
      <CustomButton
        disabled={sendData}
        onClick={handleSave}
        data-cy="preview-merge-modal-save-button"
      >
        Merge dataset
        {sendData && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ margin: '0px 10px' }}
            />
            <LoadingDots />{' '}
          </>
        )}
      </CustomButton>
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="merge-preview-assets"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
      {openDuplicatedAlert && (
        <MergeDuplicated
          continueMerge={continueMerge}
          discardMergeDuplicated={discardMergeDuplicated}
          disableMergeAllButton={disableMergeAllButton}
        />
      )}
    </>
  );
};

export default MergeDatasetsPreview;
