import { useEffect, useRef, useState, type ReactElement } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { HOME_VIEW_TYPES, PredictStatus } from 'common/interfaces/enums';

import { ReactComponent as LoadingDots } from 'assets/imgs/loading-dots.svg';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AssistantApiService } from 'chat/services/AssistantApiService';
import { type CrossTabsData } from 'common/interfaces/interfaces';
import SmallCheckbox from 'common/smallCheckbox/SmallCheckbox';
import type { ScenarioResult } from 'playground/interfaces/playground';
import { ExposeService } from 'services/ExposeService';
import {
  GAUserEvent,
  downloadDocumentFromUrl,
  nameFileRegex
} from 'utils/utils';

import {
  USER_TRACKING_CROSSTABS_ACTIONS,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import CustomButton from 'common/button/CustomButton';
import 'common/Common.scss';
import './Crosstabs.scss';
import { homeViewRenderAtom } from 'home/atoms/AtomChatActive';
import RenderViewComposed from 'home/components/renderView/RenderView';

const buildCrossTabsSelectorUi = (
  value: string[],
  opions: string[],
  action: (value: string[]) => void,
  id = '',
  validation = false,
  allowIndexes = opions.length
): JSX.Element => {
  return (
    <Select
      displayEmpty
      disabled={validation}
      id={id}
      multiple
      value={value}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return 'Select columns';
        }
        const renderSelectString = selected.join(', ');
        return renderSelectString.length > 35
          ? `${renderSelectString.slice(0, 35)}...`
          : renderSelectString;
      }}
      onChange={({ target }) => {
        action(target.value as string[]);
      }}
      IconComponent={KeyboardArrowDown}
    >
      {opions.map((accessElement: string, index: number) => {
        const found = value.includes(accessElement);
        return (
          <MenuItem
            key={`${accessElement}-${index}`}
            disabled={!(allowIndexes > index)}
            value={accessElement}
          >
            <Box className="align-center">
              <SmallCheckbox found={found} />
              {accessElement}
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};

interface CrossTabsProps {
  datasetId: string;
  mainGroup: string[];
  viewId?: string;
}

const CrossTabs = ({
  datasetId,
  mainGroup,
  viewId
}: CrossTabsProps): ReactElement => {
  const assistantApiService = AssistantApiService.getInstance();
  const exposeApiService = ExposeService.getInstance();
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);

  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const [selectedMainOptions, setSelectedMainOptions] = useState<string[]>([]);
  const [selectedCompareOptions, setSelectedCompareOptions] = useState<
    string[]
  >([]);

  const [crossTabsFileName, setCrossTabsFileName] = useState<string>();
  const [invalidName, setInvalidName] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>('');

  const [approveTabsDownload, setApproveTabsDownload] =
    useState<boolean>(false);
  const isValidDataset = mainGroup.length > 2;
  const [isLoading, setIsLoading] = useState<boolean>(!isValidDataset);

  const crossTabsNameRef = useRef<HTMLInputElement>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);

  const displayCompare = ['Select all', ...mainGroup];
  const selectedMainAll = selectedMainOptions.includes(displayCompare[0]);
  const selectedAll = selectedCompareOptions.includes(displayCompare[0]);
  const allowSelectCompareQuantity = selectedAll ? 1 : displayCompare.length;
  const allowSelectMainCompareQuantity = selectedMainAll
    ? 1
    : displayCompare.length;

  const buttonDownloadDisable =
    !approveTabsDownload ||
    isLoading ||
    invalidName ||
    crossTabsNameRef?.current?.value === '';

  useEffect(() => {
    checkDownloadApproval();
  }, [selectedMainOptions, selectedCompareOptions]);

  const returnPreviousModal = (): void => {
    setViewRender({
      type: HOME_VIEW_TYPES.DETAIL_DATASETS,
      payload: { ...viewRender?.payload },
      stored: viewRender.stored
    });
  };

  const checkDownloadApproval = (): void => {
    if (selectedMainOptions.length > 0 && selectedCompareOptions.length > 0) {
      if (!approveTabsDownload) {
        setApproveTabsDownload(true);
      } else if (hasError !== '') {
        setHasError('');
      }
    } else if (approveTabsDownload && !invalidName) {
      setApproveTabsDownload(false);
    }
  };

  const onNameChange = (): void => {
    if (
      crossTabsNameRef.current !== null &&
      (crossTabsNameRef.current.value !== undefined ||
        crossTabsFileName !== undefined)
    ) {
      setCrossTabsFileName(crossTabsNameRef.current.value);
      if (
        crossTabsNameRef.current.value === '' ||
        crossTabsNameRef.current?.value.match(nameFileRegex) === null
      ) {
        setInvalidName(true);
      } else {
        setInvalidName(false);
      }
      checkDownloadApproval();
    }
  };

  const onEditEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (
        approveTabsDownload &&
        !isLoading &&
        downloadButtonRef.current !== null
      ) {
        downloadButtonRef.current.focus();
      }
    }
  };

  const handleCrossTabsException = (validDownload: {
    status: boolean;
    message: string;
  }): void => {
    if (validDownload.status) {
      setHasError(validDownload.message);
      setIsLoading(false);
    } else if (hasError !== '' && !validDownload.status) {
      setHasError('');
    }
    GAUserEvent(
      `${userLocationVariable.current}_${USER_TRACKING_CROSSTABS_ACTIONS.CROSSTABS_ERROR}`
    );
  };

  const getCrossTabsJobStatus = async (params: {
    intervalId: NodeJS.Timer;
    crosstabsFileData: ScenarioResult;
    fileName: string;
    validDownload: { status: boolean; message: string };
  }): Promise<void> => {
    const { intervalId, crosstabsFileData, fileName, validDownload } = params;
    let jobStatus = { status: PredictStatus.FAILED, error: 'Job not found' };
    if (crosstabsFileData.runId !== undefined) {
      jobStatus = await exposeApiService.predictStatus(crosstabsFileData.runId);
    }
    switch (jobStatus.status) {
      case PredictStatus.RUNNING:
        validDownload.status = false;
        break;
      case PredictStatus.FAILED:
        clearInterval(intervalId);
        validDownload.status = true;
        validDownload.message = jobStatus.error;
        break;
      case PredictStatus.COMPLETED:
      case PredictStatus.SUCCEEDED: {
        clearInterval(intervalId);
        const responseDownload = await exposeApiService.generateCrossDownload(
          crosstabsFileData.downloadUrl
        );
        if (responseDownload.status === 200) {
          GAUserEvent(
            `${userLocationVariable.current}_${USER_TRACKING_CROSSTABS_ACTIONS.CROSSTABS_SUCCESSFUL}`
          );
          const dataToBlob = new Blob([responseDownload.data], {
            type: responseDownload.headers['content-type']
          });
          const blobURL = URL.createObjectURL(dataToBlob);
          downloadDocumentFromUrl(blobURL, fileName);
          setIsLoading(false);
        } else {
          validDownload.status = true;
        }
        break;
      }
    }
    handleCrossTabsException(validDownload);
  };

  const handleDownload = async (): Promise<void> => {
    setIsLoading(true);
    const validDownload = {
      status: true,
      message: 'Unable to generate cross tabs file. Please try again.'
    };
    const buildRequest: CrossTabsData = {
      datasetId,
      fileName: crossTabsFileName as string,
      channelId: assistantApiService.channelId,
      mainVariables: selectedMainOptions,
      variablesToCompare: selectedCompareOptions
    };

    if (viewId !== undefined) {
      buildRequest.viewId = viewId;
    }

    if (selectedAll) {
      buildRequest.variablesToCompare = mainGroup;
    }
    if (selectedMainAll) {
      buildRequest.mainVariables = mainGroup;
    }

    try {
      const crosstabsFile = await exposeApiService.generateCrossTabsFile(
        buildRequest
      );
      if (crosstabsFile.status === 200) {
        validDownload.message =
          'Unable to download cross tabs file. Please try again.';
        const { downloadUrl, runId } = crosstabsFile.data;
        if (downloadUrl !== undefined && runId !== undefined) {
          const statusInterval: NodeJS.Timer = setInterval(() => {
            try {
              void getCrossTabsJobStatus({
                intervalId: statusInterval,
                crosstabsFileData: crosstabsFile.data,
                fileName: buildRequest.fileName,
                validDownload
              });
            } catch (e) {
              clearInterval(statusInterval);
              handleCrossTabsException(validDownload);
            }
          }, 10000);
        }
      }
    } catch (error) {
      handleCrossTabsException(validDownload);
    }
  };

  const headerComponent = (
    <Typography className="header-title color-dark">
      Crosstabs analysis
    </Typography>
  );

  const bodyComponent = (
    <Box id="crosstabs-body">
      {!isValidDataset ? (
        <Typography className="errorMsg">
          Dataset does not have enough valid columns to proceed.
        </Typography>
      ) : null}
      <Typography className="ft-md">
        Produce a concise summary table by aggregating values from groups of
        data points within defined categories.
      </Typography>
      <Box id="crosstabs-body__name">
        <label className="ft-md" htmlFor="crosstabs-input">
          New file name<span>*</span>
        </label>
        <TextField
          inputRef={crossTabsNameRef}
          id="crosstabs-input"
          name="crosstabs-input"
          disabled={isLoading}
          value={crossTabsFileName}
          inputProps={{
            maxLength: 25
          }}
          onChange={onNameChange}
          onKeyUp={onEditEnter}
          variant="outlined"
          type="text"
          autoComplete="off"
          data-cy="crosstabs-name-input"
        />
        {invalidName ? (
          <Typography className="errorMsg">
            Invalid name, please input a valid name.
          </Typography>
        ) : null}
      </Box>
      <Box id="crosstabs-body__selectors">
        <Box>
          <label className="ft-md" htmlFor="crosstabs-main-group">
            Row variables<span>*</span>
          </label>
          {buildCrossTabsSelectorUi(
            selectedMainOptions,
            displayCompare,
            (value: string[]) => {
              if (value.includes(displayCompare[0])) {
                setSelectedMainOptions([displayCompare[0]]);
              } else {
                setSelectedMainOptions(value);
              }
            },
            'crosstabs-main-group',
            isLoading,
            allowSelectMainCompareQuantity
          )}
          <Typography className="ft-sm">
            The main variable to compare against the column variables.
            <br />A crosstab will be created per each of the row variables
            selected.
          </Typography>
        </Box>
        <Box>
          <label className="ft-md" htmlFor="crosstabs-variable-compare">
            Column variables<span>*</span>
          </label>
          {buildCrossTabsSelectorUi(
            selectedCompareOptions,
            displayCompare,
            (value: string[]) => {
              if (value.includes(displayCompare[0])) {
                setSelectedCompareOptions([displayCompare[0]]);
              } else {
                setSelectedCompareOptions(value);
              }
            },
            'crosstabs-variable-compare',
            isLoading,
            allowSelectCompareQuantity
          )}
          <Typography className="ft-sm">
            The variables that define the columns in the contingency table.
            <br />
            All selected variables will appear in each of the crosstabs created
            per selected row variable.
          </Typography>
        </Box>
      </Box>
      {hasError !== '' ? (
        <Alert severity="error">
          There has been an issue downloading your file {hasError}
        </Alert>
      ) : null}
    </Box>
  );

  const footerComponent = (
    <Box className="crosstabs-footer">
      <CustomButton
        variant="tertiary"
        disabled={isLoading && isValidDataset}
        onClick={returnPreviousModal}
      >
        Cancel
      </CustomButton>
      <CustomButton
        customRef={downloadButtonRef}
        variant="primary"
        disabled={buttonDownloadDisable}
        onClick={handleDownload}
      >
        Download
        {isLoading && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ margin: '0px 10px' }}
            />
            <LoadingDots />
          </>
        )}
      </CustomButton>
    </Box>
  );

  return (
    <>
      <RenderViewComposed
        id="crossTabs"
        header={headerComponent}
        body={bodyComponent}
        footer={footerComponent}
      />
    </>
  );
};

export default CrossTabs;
