import CustomTableCell, { type TableCellProps } from './CustomTableCell';
import type {
  TdCellOptions,
  ActionController
} from 'common/interfaces/interfaces';
import './CustomTable.scss';

export interface TableRowProps {
  cells: TableCellProps[];
  classes?: string;
  actionController?: ActionController;
}

export interface HeaderRowProps {
  cells: Array<{
    options?: TdCellOptions;
    jsxrest: string | JSX.Element | null;
  }>;
  classes?: string;
}

export enum TableType {
  DEFAULT = 'theme-default',
  HIGHLIGHT_1 = 'theme-highlight-1',
  HIGHLIGHT_2 = 'theme-highlight-2',
  HIGHLIGHT_3 = 'theme-highlight-3'
}

interface TableProps {
  className?: string;
  header?: HeaderRowProps;
  body: TableRowProps[];
  footer?: HeaderRowProps;
  disableHover?: boolean;
}

export const getTableTypeClass = (
  classes: TdCellOptions['classes']
): string => {
  let localClass: string = TableType.DEFAULT;
  if (classes !== undefined) {
    if (classes.type !== undefined) {
      localClass = classes.type;
    }
    if (classes.class !== undefined && typeof classes.class === 'string') {
      localClass += ` ${classes.class}`;
    }
  }
  return localClass;
};

const SingleTableRow = ({ cells, classes }: HeaderRowProps): JSX.Element => {
  return (
    <tr className={classes !== undefined ? classes : ''}>
      {cells.map((cell, index) => {
        const localClasses = getTableTypeClass(cell.options?.classes);
        return (
          <th key={`tableCell-${index}`} className={`${localClasses}`}>
            {cell.jsxrest}
          </th>
        );
      })}
    </tr>
  );
};

const RegularTableRow = ({
  cells,
  classes,
  actionController
}: TableRowProps): JSX.Element => {
  return (
    <tr
      className={classes !== undefined ? classes : ''}
      onMouseEnter={() => {
        if (actionController !== undefined) {
          if (actionController.onMouseOver !== undefined) {
            actionController.onMouseOver();
          }
        }
      }}
      onMouseLeave={() => {
        if (actionController !== undefined) {
          if (actionController.onMouseOut !== undefined) {
            actionController.onMouseOut();
          }
        }
      }}
      onClick={() => {
        if (actionController !== undefined) {
          if (actionController.action !== undefined) {
            if (actionController.values !== undefined) {
              actionController.action(actionController.values);
            } else {
              actionController.action();
            }
          }
        }
      }}
    >
      {cells.map((cell, index) => {
        if (cell.options?.isTdCell !== undefined) {
          return cell.options.isTdCell;
        } else {
          return (
            <CustomTableCell
              key={`tableCell-${index}`}
              tdData={cell.tdData}
              index={cell.index}
              options={cell.options}
              jsxrest={cell.jsxrest}
            />
          );
        }
      })}
    </tr>
  );
};

const CustomTable = ({
  disableHover = false,
  className,
  header,
  body,
  footer
}: TableProps): JSX.Element => {
  return (
    <table
      className={`${className !== undefined ? className : ''} custom-table`}
      style={{ height: '1px' }}
    >
      {header !== undefined ? (
        <thead>
          <SingleTableRow classes={header.classes} cells={header.cells} />
        </thead>
      ) : null}
      <tbody className={`${disableHover ? 'no-hover' : ''}`}>
        {body.map((row, index) => (
          <RegularTableRow
            key={`tableRow-${index}`}
            classes={row.classes}
            cells={row.cells}
            actionController={row.actionController}
          />
        ))}
      </tbody>
      {footer !== undefined ? (
        <tfoot>
          <SingleTableRow classes={footer.classes} cells={footer.cells} />
        </tfoot>
      ) : null}
    </table>
  );
};

export default CustomTable;
