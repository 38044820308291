import { type ReactElement, useState } from 'react';
import {
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomChips from 'common/customChips/CustomChips';
import '../Common.scss';

interface InputWithChipsProps {
  disabled: boolean;
  inputElements: string[];
  handleAddElement: (value: string) => void;
  onDelete: (value: string) => void;
}

const InputWithChips = ({
  disabled,
  inputElements,
  handleAddElement,
  onDelete
}: InputWithChipsProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');
  const tagExists = inputElements.includes(inputValue);

  const saveTag = (): void => {
    if (inputValue !== '' && !tagExists) {
      handleAddElement(inputValue);
      setInputValue('');
    }
  };

  return (
    <Box>
      <label htmlFor="inputWithChips">Tags</label>
      <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
        <OutlinedInput
          disabled={disabled}
          id="inputWithChips"
          inputProps={{ maxLength: 25 }}
          type="text"
          value={inputValue}
          onChange={(e) => {
            if (e?.target?.value !== null) {
              setInputValue(e.target.value);
            }
          }}
          onKeyUp={(ev) => {
            if (ev.key === 'Enter') {
              saveTag();
            }
          }}
          endAdornment={
            <InputAdornment style={{ maxWidth: '30px' }} position="end">
              <IconButton
                disabled={disabled || tagExists}
                aria-label="toggle password visibility"
                onClick={saveTag}
                edge="end"
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Box className="chips-wrapper">
        {inputElements.map((element: string, index: number) => (
          <CustomChips
            className="chip"
            key={index}
            label={element}
            handleDelete={() => {
              onDelete(element);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InputWithChips;
