import { modalControllerAtom } from 'atoms/atomModalController';
import { AssistantApiService } from 'chat/services/AssistantApiService';
import { MODAL_TYPES } from 'common/interfaces/enums';
import Save from 'mySpace/save/Save';
import type { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import Login from 'home/components/Login';
import ForgotPassword from 'home/components/ForgotPassword';
import LinkSent from 'home/components/LinkSent';
import ResetPassword from 'home/components/resetPassword/ResetPassword';
import StartChat from 'home/components/startChat/StartChat';
import UploadLink from 'mySpace/uploadManager/components/uploadLink/UploadLink';
import UploadManager from 'mySpace/uploadManager/UploadManager';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import './ModalController.scss';

const ModalController = (): ReactElement => {
  const assistantApiService = AssistantApiService.getInstance();
  const modalController = useRecoilValue(modalControllerAtom);
  const userLocationVariable = useRecoilValue(userTrackingLocation);

  const deleteCountDown = (): void => {
    // Removes the timer that keeps track of the inactive user for features that require it
    const isOptimise = userLocationVariable.current.includes(
      USER_TRACKING_LOCATION_NAMES.OPTIMISE
    );
    const isPredict = userLocationVariable.current.includes(
      USER_TRACKING_LOCATION_NAMES.PREDICT
    );
    const isNaturalFlow = isOptimise || isPredict;

    if (isNaturalFlow) {
      assistantApiService.deleteCountDown().catch(console.error);
    }
  };
  switch (modalController.type.toUpperCase()) {
    case MODAL_TYPES.START_CHAT:
      return <StartChat />;
    case MODAL_TYPES.LOGIN:
      return <Login />;
    case MODAL_TYPES.FORGOT_PASSWORD:
      return <ForgotPassword />;
    case MODAL_TYPES.LINK_SENT:
      return <LinkSent />;
    case MODAL_TYPES.RESET_PASSWORD:
      return <ResetPassword />;
    case MODAL_TYPES.SAVE_MODEL:
    case MODAL_TYPES.SAVE_DATASET: {
      deleteCountDown();
      const {
        context,
        datasetId,
        datasetUrl,
        dataContext,
        performanceMetric,
        userId,
        accountId
      } = modalController.payload;

      return (
        <Save
          context={context}
          dataContext={dataContext}
          performanceMetric={performanceMetric}
          userId={userId}
          accountId={accountId}
          datasetId={datasetId}
          datasetUrl={datasetUrl}
        />
      );
    }
    case MODAL_TYPES.UPLOAD_LINK:
      return <UploadLink />;
    case MODAL_TYPES.UPLOAD_MANAGER: {
      deleteCountDown();
      const { fileId, runId, uri } = modalController.payload;
      return <UploadManager fileId={fileId} uri={uri} runId={runId} />;
    }
  }
  return <></>;
};
export default ModalController;
